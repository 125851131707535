import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairWeavingForMen = () => {

  const seoData = {
    title: 'Hair Weaving For Men in Delhi - Radiance Hair Studio',
    description: 'Explore the world of hair weaving in Delhi with Radiance Hair Studio. Discover an exciting method for natural-looking results. Visit us today.',
    keywords: ['hair weaving for men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Weaving-for-Men's.jpg",
      "https://www.radiancehairstudio.com/hair-weaving-for-mens1.png",
      "https://www.radiancehairstudio.com/hair-weaving-for-mens1.png",
      "https://www.radiancehairstudio.com/hair-weaving-for-mens2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/hair-weaving-for-mens.php",
    "category": "https://www.radiancehairstudio.com/hair-weaving-for-mens.php",
    "description": "Explore the world of hair weaving in Delhi with Radiance Hair Studio. Discover an exciting method for natural-looking results. Visit us today.",
    "disambiguatingDescription": "hair weaving for men in Delhi",
    "serviceType": "Hair Weaving for Men's"
  };

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Weaving-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Weaving in Delhi for Men's</h2>
          <p>
            Among the <a href="/non-surgical-hair-replacement-for-men.php" style={{ color: "#373737" }}>Non-Surgical Hair replacement</a> plans, three distinct methods are usually applied – glue, clip or tape. However, many think those are going to harm your natural hair, although there is not a single side-effect from the entire thing.Hair weaving in Delhi is the ideal strategy to use for individuals. This is the method that is going to give you natural beauty and here the process is done by full Weaving.
          </p>

          <h2>Radiance Strom</h2>
          <p>
            Radiance Hair Salon will use solely your natural hair to create a structure on your head, and then they will stitch the elongations of your natural hair into the structure.The process is a long drawn one, but the change you will find is perfectly natural.
          </p>

          <p>
            The specialty of this applied method is that – your extensions can never be identified. Your secret will remain hidden even if you take a closer look at your skull.Don’t get bothered of the tension of side effectcs from hair weaving. We would like to mention here that the products
            we use are completely harmless to your sensitive skin and our extensions are exclusively designed with 100% original human hair which will not only give you a natural look but also gives naturally a comfortable feel on  your head.

          </p>

          <p>
            Having experts who are adept at doing this kind of hairdo is what makes you radiant. It’s a long
            drawn process and our professionals are competent enough to give you what you seek in style.
          </p>

          <p>Let's examine the advantages of hair weaving in more detail. </p>
        </div>
      )
    }
  ];

  const sectionS3 = [
    {
      smallImg: "/hair-weaving-for-mens1.png",
      largeImg: "/hair-weaving-for-mens2.png",
      content: (
        <div>
          <h2>Advantages of Hair Weaving</h2>
          <p></p>
          <ul>
            <li>
              One of the biggest advantages of hair weaving is the natural and fuller look it provides. This technique enhances your hair’s appearance, making it seamless and voluminous.
            </li>
            <li>
            The foundation of hair weaving lies in a net-like structure that is carefully crafted on the scalp. Once this base is set, high-quality natural hair extensions are integrated to create the desired look.
            </li>
            <li>
            While hair weaving is highly effective for men, women may require some trimming of their natural hair to achieve the best results.
            </li>
            <li>
            Another key benefit is its longevity. Unlike temporary <a href="/hair-bonding-for-men.php" style={{ color: "#373737" }}> hair solutions,</a> hair weaving lasts for an extended period. You can wash, style, and even use shampoos or serums without concern. However, professional maintenance at a salon is recommended to ensure the weave stays intact and looks flawless.
            </li>
          </ul>
        </div>
      )
    }
  ];

  const sectionS4 = [
    {
      title: "FAQs on Hair Weaving",
      description: "",
      description2: "",
      faq: [
        {
          title: "Will my natural hair be affected?",
          description: "Not at all! Hair weaving does not require the use of glue, tapes, or any harsh chemicals. Your natural hair is carefully interwoven to create a structured base for the extensions. Since this is a non-surgical hair replacement method, there is no risk of damage to your natural hair."
        },
        {
          title: "Are there any side effects?",
          description: "Hair weaving is a completely external process, and no chemicals are involved. This means the chances of any side effects—whether on your scalp or natural hair—are minimal."
        },
        {
          title: "Can I change my hairstyle daily?",
          description: "Absolutely! Hair weaving extensions are typically secured using clips or double-sided tapes, making them easy to remove and reattach. This allows you to switch up your hairstyle effortlessly whenever you like."
        },
        {
          title: "Do I need to avoid swimming or other activities?",
          description: "No, there’s no restriction! Since hair weaving uses natural hair and safe attachment methods, you can enjoy swimming and other daily activities without worrying about any damage."
        }
      ],
    }
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Weaving for Men's" banner="/hair-weaving-for-men-banner.png" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Weaving for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairWeavingForMen;
